<template>
  <div class="mt-4">
    <v-dialog ref="addClaimDialog" v-model="addClaimDialog" width="500">
      <AddClaimDialog :claim-item="claimItem" :customer-id="customer_id" :customer="customer"
        :claimCategories="claimCategories" @close-dialog="addClaimDialog = false"></AddClaimDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="flex-grow-1 text-right">
        <v-btn height="28" class="btn-new-task" color="#CFD3FE" depressed min-width="100" @click="addClaim">
          <v-icon left> mdi-plus </v-icon> クレーム
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ClaimList :customer="customer" :sites="sites" :claims="claims" :claimCategories="claimCategories"
          :customer-id="customer_id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClaimList from "@/components/layout/customer/ClaimList.vue";
import AddClaimDialog from '@/components/layout/customer/Claim/Dialog'
export default {
  name: "Claims",
  props: {
    customer_id: {
      type: [String, Number],
      required: true,
    }
  },
  components: { ClaimList, AddClaimDialog },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    claims() {
      return this.$store.getters.allCustomerClaims;
    },
    sites() {
      return this.customer.sites.map((sites, key) => {
        return { value: key, text: sites.name };
      });
    },
    claimCategories() {
      return this.$store.getters.allClaimCategories.map((ClaimCategories) => {
        return { value: ClaimCategories.id, text: ClaimCategories.name };
      });
    }
  },
  created() {
    this.getDataFromApi()
  },
  data() {
    return {
      addClaimDialog: false,
      claimItem: {},
      data: {
        site: 0
      }
    };
  },
  methods: {
    addClaim() {
      this.addClaimDialog = true
      this.claimItem = {}
    },
    getDataFromApi() {
      this.$store.dispatch('GET_CLAIM_CATEGORIES').then(() => {
        this.loading = false
      })
    }
  }
};
</script>
