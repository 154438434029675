<template>
  <v-card>
    <v-card-text>
      <v-row class="py-4">
        <v-col cols="12" class="text-center text-heading-1">
          クレーム管理
          <div>
            <v-btn depressed class="close-button" fab color="#E27C7C" width="20" height="20"
              @click="$emit('close-dialog')">
              <v-icon size="14">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <validation-observer ref="observer">
        <form @submit.prevent="save">
          <v-col cols="12">
            <div class="form-header mb-2">タスク</div>
            <div>
              <validation-provider v-slot="{ errors }" name="tasks" rules="required">
                <v-select class="form-text" dense outlined placeholder="task id / 日付 / サービス名 " hide-details
                  :error-messages="errors" :error="errors.length !== 0" v-model="data.task_id" :items="tasks"
                  item-value="value"></v-select>
              </validation-provider>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-header mb-2">クレーム分類</div>
            <div>
              <validation-provider v-slot="{ errors }" name="クレーム分類" rules="required">
                <v-select class="form-text" outlined dense :error-messages="errors" :error="errors.length !== 0"
                  :items="claimCategories" hide-details="auto" placeholder="未選択" chips multiple clearable
                  v-model="data.claim_category_id">
                  <template v-slot:selection="{ attrs, item, select }">
                    <v-chip class="mb-1" small close v-bind="attrs" @click="select"
                      @click:close="removeTag(item.value)">
                      <span>{{  item.text  }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </validation-provider>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-header mb-2">クレーム内容</div>
            <div>
              <validation-provider v-slot="{ errors }" name="des" rules="required">
                <v-textarea class="form-text" dense outlined placeholder="クレームの内容を箇条書きにしてください" hide-details
                  :error-messages="errors" :error="errors.length !== 0" v-model="data.description" @keyup='desCount()'
                  :maxlength="descriptionMaxLength"></v-textarea>
              </validation-provider>
              <div class="text-right">
                {{  totalDescharacter ? totalDescharacter : data.description.length  }} / {{  descriptionMaxLength  }} 文字
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-header mb-2">対応内容</div>
            <div>
              <validation-provider v-slot="{ errors }" name="res" rules="required">
                <v-textarea class="form-text" dense outlined placeholder="どのように対応したかを記入してください" hide-details
                  :error-messages="errors" :error="errors.length !== 0" v-model="data.response" @keyup='resCount()'
                  :maxlength="responseMaxLength"></v-textarea>
              </validation-provider>
              <div class="text-right">
                {{  totalRescharacter ? totalRescharacter : data.response.length  }} / {{  responseMaxLength  }} 文字
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-row align="center">
              <v-col class="text-right" cols="auto" v-if="this.claimItem && this.claimItem.id">
                <v-btn @click="deleteTask" color="#AD4545" text>
                  <v-icon left> mdi-delete </v-icon> 削除する
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="save" color="#4F55A7" class="white--text" width="100">登録
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: 'Dialog',
  props: {
    customerId: {
      type: String,
      default: '',
      required: true
    },
    claimItem: {
      type: Object,
      default: () => { },
      required: false
    },
    customer: {
      type: Object,
      required: true,
    },
    claimCategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    tasks() {
      return this.$store.getters.customerTasksById.map((tasks) => {
        if (!tasks.service.id == "0") {
          return { value: tasks.id, text: tasks.id + " / " + tasks.date + " / " + tasks.service.name };
        }
      });
    },
  },
  watch: {
    claimItem: function () {
      this.data.description = ''
      this.data.response = ''
      this.data.task_id = ''
      this.data.claim_category_id = ''
      this.data.created_by = ''
      this.data.updated_by = ''
      this.patchEditFormValues()
    }
  },
  created() {
    this.patchEditFormValues()
  },
  methods: {
    ...mapMutations(['confirmationModal']),
    patchEditFormValues() {
      if (this.claimItem && this.claimItem.id) {
        this.data.description = this.claimItem.description
        this.data.response = this.claimItem.response
        this.data.task_id = this.claimItem.task_id
        this.data.claim_category_id = this.claimItem?.categories.map(category => category.id)
        this.data.customer_id = this.customerId,
          this.data.created_by = this.user.id
        this.data.updated_by = this.user.id
      } else {
        this.data.description = ''
        this.data.response = ''
        this.data.task_id = ''
        this.data.claim_category_id = ''
        this.data.customer_id = this.customerId
        this.data.created_by = this.user.id
        this.data.updated_by = this.user.id
      }
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        if (this.claimItem && this.claimItem.id) {
          this.data.claim_id = this.claimItem.id
          let data = this.data
          let claim_id = this.claimItem.id

          this.$store.dispatch('CUSTOMER_EDIT_CLAIM', { data, claim_id }).then(
            response => {
              if (response.data.status == 'success') {
                this.$router.go(this.$router.currentRoute)
                this.$emit('close-dialog')
              }
            },
            error => {
              throw error
            }
          )
        } else {
          this.$store.dispatch('CUSTOMER_CREATE_CLAIM', this.data).then(
            response => {
              if (response.data.status == 'success') {
                this.$router.go(this.$router.currentRoute)
                this.$emit('close-dialog')
              }
            },
            error => {
              throw error
            }
          )
        }
      })
    },
    deleteTask() {
      this.confirmationModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    processDelete() {
      if (this.claimItem && this.claimItem.id) {
        let claim_id = this.claimItem.id
        this.$store.dispatch('CUSTOMER_DELETE_CLAIM', { claim_id }).then(
          response => {
            if (response.data.status == 'success') {
              this.$router.go(this.$router.currentRoute)
              this.$emit('close-dialog')
            }
          },
          error => {
            throw error
          }
        )
      }
    },
    desCount: function () {
      this.totalDescharacter = this.data.description.length;
    },
    resCount: function () {
      this.totalRescharacter = this.data.response.length;
    },
    removeTag(item) {
      this.data.claim_category_id = this.data.claim_category_id.filter((claimCategories) => claimCategories !== item);
    },
  },
  data() {
    return {
      choosenPhoto: null,
      descriptionMaxLength: 1000,
      responseMaxLength: 1000,
      data: {
        description: "",
        response: "",
        task_id: "",
        claim_category_id: [],
        customer_id: "",
        created_by: "",
        updated_by: "",
        claim_id: ""
      },
      totalDescharacter: 0,
      totalRescharacter: 0
    }
  }
}
</script>

<style lang="scss" src="./Dialog.scss" scoped>
</style>
