<template>
  <v-container class="claim-box mt-4 pa-5">
    <v-dialog ref="addClaimDialog" v-model="addClaimDialog" width="500">
      <AddClaimDialog
        :claim-item="claimItem"
        :customer-id="customerId"
        :customer="customer"
        :claimCategories="claimCategories"
        @close-dialog="addClaimDialog = false"
      ></AddClaimDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="claim-box-headline">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="mr-4" color="#C62E2E" size="10"
              >mdi-checkbox-blank-circle</v-icon
            >
            クレーム
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="flex-grow-1">
        <v-row v-for="(item, index) in claims" :key="item.id" align="center">
          <v-col cols="12" class="claim-box-date text-heading-3">
            <v-row>
              <v-col cols="10">
                <span class="mr-3">{{ item.date }}</span>
                <v-chip
                  color="#8C5F5F"
                  text-color="white"
                  v-for="(category, index) in item.categories"
                  :key="index"
                  label
                  small
                  class="mr-3 mb-2"
                >
                  {{ category.name }}
                </v-chip>
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn
                  fab
                  x-small
                  color="editColor"
                  class="white--text"
                  @click="editClaim(item)"
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="claim-box-title py-0 text-heading-3">
            クレーム内容
          </v-col>
          <v-col cols="12" class="claim-box-content pt-0 text-heading-3">
            {{ item.description }}
          </v-col>
          <v-col cols="12" class="claim-box-title py-0 text-heading-3">
            対応内容
          </v-col>
          <v-col cols="12" class="claim-box-content pt-0 text-heading-3">
            {{ item.response }}
          </v-col>
          <v-col cols="12" v-if="index !== claims.length - 1">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddClaimDialog from "@/components/layout/customer/Claim/Dialog";
export default {
  name: "BasicInformation",
  components: { AddClaimDialog },
  props: ["customer", "sites", "claims", "claimCategories", "customerId"],
  methods: {
    editClaim: function(item) {
      this.addClaimDialog = true;
      this.claimItem = item;
    }
  },
  mounted() {
    this.$store.dispatch('CUSTOMER_TASK_GET', this.customer.id)
  },
  data() {
    return {
      addClaimDialog: false,
      claimItem: {}
    };
  }
};
</script>

<style lang="scss" scoped>
.claim-box {
  background: #ffeeee;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &-headline {
    font-size: 18px;
    color: #c62e2e;
  }

  font-size: 16px;

  &-date {
    color: #878787;
  }

  &-title {
    color: #878787;
  }

  &-content {
    color: #333333;
    word-break: break-all;
  }
}
</style>
